/* eslint-disable */
export function generateMaze(width, height) {
    let maze = Array(height).fill().map(() => Array(width).fill(1));

    // Carve paths inside the maze, leaving the outer walls intact
    carvePaths(1, 1, maze, width - 2, height - 2);

    // Create a single entrance
    maze[height - 2][1] = 0; // Entrance at the bottom left corner

    return maze;
}

function carvePaths(x, y, maze, width, height) {
    const directions = shuffle([[-1, 0], [1, 0], [0, -1], [0, 1]]); // Shuffle directions

    // Mark the current cell as part of the maze
    maze[y][x] = 0;

    // Explore each direction
    for (let [dx, dy] of directions) {
        const nx = x + dx * 2, ny = y + dy * 2;

        // Check if the new cell is within bounds and has not been visited
        if (isValid(nx, ny, width, height, maze)) {
            // Carve a path to the new cell
            maze[y + dy][x + dx] = 0;
            carvePaths(nx, ny, maze, width, height);
        }
    }
}

function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function isValid(x, y, width, height, maze) {
    if (x < 0 || x >= width || y < 0 || y >= height) return false; // Check bounds
    return maze[y][x] === 1; // Check if the cell is unvisited (wall)
}

// Genetic Algorithm
class Path {
    constructor(steps) {
        this.steps = steps || [];
        this.fitness = 0;
        console.log("New Path steps:", this.steps); // Log to check the steps
    }

    calculateFitness(goalX, goalY) {
        let x = 0, y = 0;
        for (const step of this.steps) {
            if (step === 'up') y--;
            else if (step === 'down') y++;
            else if (step === 'left') x--;
            else if (step === 'right') x++;

            // Boundaries
            if (x < 0 || y < 0 || x >= goalX || y >= goalY) {
                this.fitness = 0;
                return;
            }
        }
        // Calculate fitness based on distance to the goal
        this.fitness = 1 / (Math.abs(goalX - x) + Math.abs(goalY - y) + 1);
        console.log("Fitness:", this.fitness); // Log to check the fitness calculation
    }

}

function crossover(parent1, parent2) {
    const midpoint = Math.floor(Math.random() * parent1.steps.length);
    const childSteps = parent1.steps.slice(0, midpoint).concat(parent2.steps.slice(midpoint));
    let child = new Path(childSteps);
    console.log("New child after crossover:", child); // Log to check the child
    return child;
}

function mutate(path, mutationRate) {
    for (let i = 0; i < path.steps.length; i++) {
        if (Math.random() < mutationRate) {
            const newStep = ['up', 'down', 'left', 'right'][Math.floor(Math.random() * 4)];
            path.steps[i] = newStep;
        }
    }
    console.log("Path after mutation:", path); // Log to check the mutated path
}

export function geneticAlgorithm(maze, populationSize, steps, mutationRate, generations) {
    console.log("GA parameters - Population Size:", populationSize, "Steps:", steps);

    let population = [];
    for (let i = 0; i < populationSize; i++) {
        let path = new Path(Array(steps).fill().map(() => ['up', 'down', 'left', 'right'][Math.floor(Math.random() * 4)]));
        population.push(path);
    }    
    
    console.log("Initial population:", population); // Log to check the initial population
    
    for (let g = 0; g < generations; g++) {
        for (let path of population) {
            path.calculateFitness(maze[0].length - 1, maze.length - 1);
        }

        population.sort((a, b) => b.fitness - a.fitness);

        let newPopulation = [];
        for (let i = 0; i < populationSize; i++) {
            const parent1 = population[Math.floor(Math.random() * populationSize / 2)];
            const parent2 = population[Math.floor(Math.random() * populationSize / 2)];
            let child = crossover(parent1, parent2);
            mutate(child, mutationRate);
            newPopulation.push(child);
        }

        population = newPopulation;
    }

    if (population.length > 0 && population[0] && Array.isArray(population[0].steps)) {
        return population[0].steps; // Return the steps of the fittest path
    } else {
        console.error("Population is empty or invalid after genetic algorithm processing");
        return []; // Return an empty array or handle this case appropriately
    }
    
}
