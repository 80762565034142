<template>
    <div id="control-panel">
      <button @click="start">Start</button>
      <button @click="reset">Reset</button>
      <!-- Additional controls for GA parameters -->
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      start() {
        this.$emit('start');
      },
      reset() {
        this.$emit('reset');
      }
    }
  };
  </script>
  