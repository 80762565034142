<template>
  <div id="app">
    <maze-component ref="maze" :robotPath="bestPath" />
    <control-panel @start="startSimulation" @reset="resetSimulation" />
  </div>
</template>
<script>
import MazeComponent from './components/MazeComponent.vue';
import ControlPanel from './components/ControlPanel.vue';
import { geneticAlgorithm } from './algorithms'; // Removed generateMaze from import

export default {
  components: {
    MazeComponent,
    ControlPanel
  },
  data() {
    return {
      bestPath: [], // Only maintain bestPath here
      // other necessary states
    };
  },
  methods: {
    startSimulation() {
      // Define the parameters for the genetic algorithm
      const populationSize = 20; // Example value
      const steps = 50; // Example value
      const mutationRate = 0.1; // Example value
      const generations = 100; // Example value

      // Run the genetic algorithm to get the best path
      this.bestPath = geneticAlgorithm(this.$refs.maze.localMaze, populationSize, steps, mutationRate, generations);

      // Check if bestPath is valid
      if (Array.isArray(this.bestPath) && this.bestPath.length > 0) {
        // Reset robot's position to the entrance
        this.$refs.maze.robot.setPosition(1, this.$refs.maze.mazeHeight - 2);

        // Animate the robot
        this.$refs.maze.animateRobot(this.bestPath);
      } else {
        console.error("Invalid bestPath from genetic algorithm");
      }
    },

    resetSimulation() {
      // Reset logic
      this.bestPath = [];
      this.$refs.maze.resetMaze(); 
    }
  }
};
</script>
