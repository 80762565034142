<template>
  <div id="maze-container" ref="mazeContainer"></div>
</template>

<script>
import * as THREE from 'three';
import { Robot } from './Robot';
import { markRaw } from 'vue';
import { generateMaze } from '../algorithms';

export default {
  props: ['robotPath'], // Remove 'maze' if it's not a prop passed from a parent component
  data() {
    return {
      localMaze: null,
      mazeWidth: 40,  // Define the maze width
      mazeHeight: 40, // Define the maze height
    };
  },
  created() {
    this.localMaze = generateMaze(this.mazeWidth, this.mazeHeight); // Generate the maze
    if (!this.localMaze || !Array.isArray(this.localMaze)) {
      console.error("Generated maze is not an array");
      return;
    }
    if (this.localMaze.length === 0 || !Array.isArray(this.localMaze[0]) || this.localMaze[0].length === 0) {
      console.error("Generated maze is empty or not properly structured");
      return;
    }
    this.localMaze[0][0] = 0; // Modify the local copy
  },
  mounted() {
    if (this.$refs.mazeContainer && this.localMaze) {
      this.initThree();
      this.renderMaze();
    }
  },
  unmounted() {
    // Clean up Three.js objects and listeners
    this.scene.dispose();
    this.renderer.dispose();
    // Additional cleanup if necessary
  },
  methods: {
    initMaze() {
      this.resetMaze();
    },

    adjustCamera() {
      // Calculate the bounds of the maze
      const mazeBounds = {
        width: this.localMaze[0].length,
        height: this.localMaze.length
      };

      // Determine the furthest distance across the maze
      const maxDimension = Math.max(mazeBounds.width, mazeBounds.height);

      // Calculate the required distance to fit the maze in view
      const distance = maxDimension / (2 * Math.tan((this.camera.fov * Math.PI) / 360));

      // Update the camera position
      this.camera.position.set(mazeBounds.width / 2 - 0.5, -(mazeBounds.height / 2 - 0.5), distance);
      this.camera.lookAt(new THREE.Vector3(mazeBounds.width / 2 - 0.5, -(mazeBounds.height / 2 - 0.5), 0));
    },
    resetMaze() {
      // Clear the scene without regenerating the maze
      this.clearScene(); // Implement this method to remove maze objects

      // Reset the robot
      this.robot.setPosition(1, this.localMaze.length - 2);
      this.localMaze = generateMaze(this.mazeWidth, this.mazeHeight); // Generate the maze
      this.renderMaze();
      // Additional reset logic if needed
    },
    clearScene() {
      for (let i = this.scene.children.length - 1; i >= 0; i--) {
        const object = this.scene.children[i];
        // Check if the object is part of the maze
        if (object.userData.isMaze) {
          this.scene.remove(object);
          // Optional: Dispose of geometry and material if they won't be reused
          if (object.geometry) object.geometry.dispose();
          if (object.material) object.material.dispose();
        }
      }

    },
    initThree() {
      const mazeWidth = this.$refs.mazeContainer.offsetWidth;
      const mazeHeight = this.$refs.mazeContainer.offsetHeight;

      // Calculate the center of the maze
      const mazeCenterX = mazeWidth / 2;
      const mazeCenterY = mazeHeight / 2;

      // Adjust these values as needed
      this.camera = new THREE.PerspectiveCamera(60, mazeWidth / mazeHeight, 0.1, 1000);
      //this.camera.position.z = 5;

      // Adjust camera position
      this.camera.position.x = mazeCenterX - mazeWidth / 2;
      this.camera.position.y = -(mazeCenterY - mazeHeight / 2); // Y is inverted in Three.js
      this.camera.position.z = Math.max(mazeWidth, mazeHeight); // Ensure the camera is far enough away
      this.camera.lookAt(new THREE.Vector3(mazeCenterX, -mazeCenterY, 0));
      // Scene
      this.scene = markRaw(new THREE.Scene());

      // Add ambient light
      const ambientLight = new THREE.AmbientLight(0x404040);
      this.scene.add(ambientLight);

      // Add directional light
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(5, 5, 5);
      this.scene.add(directionalLight);

      // Renderer
      this.renderer = markRaw(new THREE.WebGLRenderer());
      this.renderer.setSize(mazeWidth, mazeHeight);
      this.$refs.mazeContainer.appendChild(this.renderer.domElement);

      // Robot
      this.robot = markRaw(new Robot(this.scene, mazeWidth, mazeHeight));

      this.animate();
    },
    renderMaze() {
      // Add ambient light
      const ambientLight = new THREE.AmbientLight(0x404040);
      ambientLight.userData.isMaze = true;
      this.scene.add(ambientLight);

      // Add directional light for shading
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight.position.set(1, 1, 1);
      directionalLight.userData.isMaze = true;
      this.scene.add(directionalLight);

      // ... existing code to render the maze ...
      for (let y = 0; y < this.localMaze.length; y++) {
        for (let x = 0; x < this.localMaze[y].length; x++) {
          if (this.localMaze[y][x] === 1) {
            const geometry = new THREE.BoxGeometry(1, 1, 1);
            const material = new THREE.MeshLambertMaterial({ color: 0x00ff00 });
            const cube = new THREE.Mesh(geometry, material);
            cube.position.set(this.mazeWidth / 2 + x - this.localMaze[y].length / 2, (-1)*this.mazeHeight / 2 + -y + this.localMaze.length / 2, 0);
            cube.userData.isMaze = true; // Mark the cube as part of the maze
            this.scene.add(cube);
          }
        }
      }
      this.adjustCamera();

    },
    animateRobot(path) {
      // Assuming you have a method in the Robot class to update its position
      const moveRobot = (direction) => {
        switch (direction) {
          case 'up': this.robot.move(0, 1); break; // Move robot up
          case 'down': this.robot.move(0, -1); break; // Move robot down
          case 'left': this.robot.move(-1, 0); break; // Move robot left
          case 'right': this.robot.move(1, 0); break; // Move robot right
        }
      };

      // Simple animation logic using setTimeout (can be improved with requestAnimationFrame)
      path.forEach((step, index) => {
        setTimeout(() => {
          moveRobot(step);
        }, index * 1000); // Adjust timing as needed
      });
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    }
  }
};
</script>

<style>
#maze-container {
  width: 100%;
  height: 100vh;
}
</style>