import * as THREE from 'three';

export class Robot {
  constructor(scene, width, height) {
    const geometry = new THREE.CylinderGeometry(0.5, 0.5, 1, 32);
    const material = new THREE.MeshStandardMaterial({ color: 0xff0000, flatShading: true });
    this.mesh = new THREE.Mesh(geometry, material);
    
    this.mesh.rotation.x = Math.PI / 2;
    this.mesh.userData.isMaze = true;

    // Initialize the position property
    this.position = { x: 0, y: 0 };

    // Set the initial position of the robot
    this.setPosition(1, height - 2, width, height);

    // Add robot mesh to the scene
    scene.add(this.mesh);
  }

  setPosition(x, y, width, height) {
    this.mesh.position.set(x - width / 2, -y + height / 2, 0);
  }
  move(dx, dy) {
    // Update the position
    this.moveTo(this.position.x + dx, this.position.y + dy);
  }
  
  moveTo(x, y) {
    // Update the robot's position
    this.position.x = x;
    this.position.y = y;
  
    // Translate the mesh to the new position
    this.mesh.position.set(
      x - this.width / 2, 
      -y + this.height / 2, 
      this.mesh.position.z // Keep the z-position unchanged
    );
  }  
  
  animate(path, onComplete) {
    let stepIndex = 0;
    const step = () => {
      if (stepIndex < path.length) {
        const direction = path[stepIndex];
        switch (direction) {
          case 'up': this.moveTo(this.position.x, this.position.y + 1); break;
          case 'down': this.moveTo(this.position.x, this.position.y - 1); break;
          case 'left': this.moveTo(this.position.x - 1, this.position.y); break;
          case 'right': this.moveTo(this.position.x + 1, this.position.y); break;
        }
        stepIndex++;
        requestAnimationFrame(step); // Continue the animation
      } else if (onComplete) {
        onComplete(); // Call the completion callback, if provided
      }
    };
    step(); // Start the animation
  }
}
